import mem from "mem";
import { caller, CallerOptions } from "postmsg-rpc";
import { logger } from "@diffuse.tv/player-core";

const debug = logger(`IPFS Web Client`, "debug");

const getRpcOptions = mem(async () => {
  const rpcOptions: CallerOptions = {
    addListener: (...args) => navigator.serviceWorker.addEventListener(...args),
    removeListener: (...args) =>
      navigator.serviceWorker.removeEventListener(...args),

    async postMessage(data: any) {
      debug(`Posting a message:`, data);
      self.navigator.serviceWorker.controller?.postMessage(data);
    },
  };

  return rpcOptions;
});

// Client interface:
// init(ipfsConfiguration: Object): Promise<true>
export const init = async () => {
  const options = await getRpcOptions();
  return caller<boolean, []>("ipfs_init", options)();
};

// start(): Promise<boolean>
export const start = async () => {
  const options = await getRpcOptions();
  return caller<boolean, []>("ipfs_nodeStart", options)();
};

// stop(): Promise<boolean>
export const stop = async () => {
  const options = await getRpcOptions();
  return caller<boolean, []>("ipfs_nodeStop", options)();
};

// status(): Promise<"STARTED" | "STOPPED">
export const status = async () => {
  const options = await getRpcOptions();
  return caller<"STARTED" | "STOPPED", []>("ipfs_nodeStatus", options)();
};

// addFromUrl(url: string): Promise<string> // returns the added cid multihash
export const addFromURL = async (url: string) => {
  const options = await getRpcOptions();
  return caller<string, [string]>("ipfs_addFromURL", options)(url);
};

// hasHashInNetwork(multihash: string): Promise<boolean>
export const hasHashInNetwork = async (multihash: string) => {
  const options = await getRpcOptions();
  return caller<boolean, [string]>("ipfs_hasHashInNetwork", options)(multihash);
};

// listPins(): Promise<string[]>
export const listPins = async () => {
  const options = await getRpcOptions();
  return caller<string[], []>("ipfs_listPins", options)();
};

// isPinned(multihash: string): Promise<boolean>
export const isPinned = async (multihash: string) => {
  const options = await getRpcOptions();
  return caller<boolean, [string]>("ipfs_isPinned", options)(multihash);
};

// pin(multihash: string): Promise<boolean>
export const pin = async (multihash: string) => {
  const options = await getRpcOptions();
  return caller<boolean, [string]>("ipfs_pin", options)(multihash);
};

// unpin(multihash: string): Promise<boolean>
export const unpin = async (multihash: string) => {
  const options = await getRpcOptions();
  return caller<boolean, [string]>("ipfs_unpin", options)(multihash);
};

// repoGarbageCollector(): Promise<boolean>
export const repoGarbageCollector: () => Promise<string[]> = async () => {
  const options = await getRpcOptions();
  return caller<string[], []>("ipfs_repoGarbageCollector", options)();
};

// export `getURL` method
export const getURL = (multihash: string) => `/ipfs/${multihash}`;

// @ts-ignore
window.ipfs = {
  init,
  start,
  stop,
  status,
  addFromURL,
  hasHashInNetwork,
  listPins,
  isPinned,
  pin,
  unpin,
  repoGarbageCollector,
  getURL,
};
