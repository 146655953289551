import Fingerprint2 from "fingerprintjs2";
import mem from "mem";
import { createHash } from "crypto";

const get = mem(Fingerprint2.getPromise);

export default async function getUniqueID(seed: string = "") {
  const data = await get();
  const hash = createHash("sha256")
    .update(JSON.stringify(data))
    .update(seed)
    .digest("hex");
  return `web:${hash}`;
}
