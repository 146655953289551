import React, { FC, SVGProps } from "react";

const Orientation: FC<Partial<SVGProps<SVGSVGElement>> & { fill?: string }> = (
  props
) => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    fill="#2E384D"
    stroke="#2E384D"
    strokeLinejoin="round"
    strokeWidth="2"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M52.5 5H5v70h20v20h70V47.5H52.5V5zM90 90H30V52.5h60V90zM10 10h37.5v37.5H25V70H10V10z" />
    <path d="M65 10V5l-7.5 7.5L65 20v-5c.9 0 1.8.1 2.7.2 8.9 1.2 15.9 8.2 17.1 17.1.1.9.2 1.8.2 2.7h-5l7.5 7.5L95 35h-5c0-13.8-11.2-25-25-25z" />
  </svg>
);

export default Orientation;
