import React, { PureComponent } from "react";
import SplitScreen from "../components/split-screen";
import type { DiffuseTvState } from "@diffuse.tv/player-core";

export default class SetupComponent extends PureComponent<{
  state: DiffuseTvState;
}> {
  render() {
    return (
      <SplitScreen
        state={this.props.state}
        title="Quick setup"
        description="Finish setup in the Diffuse.tv's dashboard. This Player will then be ready for playback."
      />
    );
  }
}
