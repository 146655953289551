import React, { SVGProps, FC } from "react";

const Logo: FC<Partial<SVGProps<SVGSVGElement>> & { fill?: string }> = ({
  fill,
  ...props
}) => (
  <svg viewBox="10 0 260 50" {...props}>
    <switch
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || "#ffffff"}
      transform="matrix(.5 0 0 .5 10 0)"
    >
      <g>
        <path d="M50 0C22.44 0 0 22.42 0 49.98A50.07 50.07 0 0050 100c27.56 0 50-22.44 50-50.02A50.05 50.05 0 0050 0zm0 90.9A40.95 40.95 0 019.1 49.98C9.1 27.43 27.44 9.1 50 9.1s40.9 18.32 40.9 40.88A40.95 40.95 0 0150 90.9z" />
        <path d="M22.37 31h55.26a33.77 33.77 0 00-7.75-8H30.12c-3 2.22-5.63 4.92-7.75 8zM16.7 46h66.6c-.33-2.79-1-5.47-1.98-8H18.68a33.23 33.23 0 00-1.98 8zm1.99 16H81.3a33.3 33.3 0 001.98-8H16.71c.33 2.78 1 5.47 1.98 8zm11.44 15h39.74a33.8 33.8 0 007.75-8H22.38a33.8 33.8 0 007.75 8z" />
      </g>
    </switch>
    <path
      fill={fill || "#ffffff"}
      d="M75.4 14.2v-.01c2.98 0 5.81 1.07 7.95 3.21 3.9 3.9 3.9 11.5 0 15.39a11.14 11.14 0 01-7.96 3.2H70V14.2h5.4zm0 17.4h.02c1.79 0 3.44-.5 4.71-1.77s1.78-2.95 1.78-4.75c0-1.79-.5-3.47-1.8-4.75a6.44 6.44 0 00-4.73-1.8h-1v13.08h1.02zm18.37 4.38V14.15h4.38v21.83h-4.38zm12.4-21.83h13.1v4.36h-8.72v4.38h6.54v4.36h-6.54v8.73h-4.37V14.15zm20.6 0h13.1v4.36h-8.73v4.38h6.54v4.36h-6.54v8.73h-4.37V14.15zm33.73 0h4.4V27.3c0 2.43-.85 4.5-2.57 6.22s-3.79 2.57-6.2 2.57c-2.42 0-4.5-.85-6.22-2.57s-2.57-3.8-2.57-6.22V14.15h4.4V27.3c0 1.2.44 2.24 1.29 3.09s1.88 1.29 3.09 1.29c1.19 0 2.22-.44 3.08-1.3s1.3-1.87 1.3-3.08V14.15zm18.91 17.53l-.01-.03c1.2 0 2.2-1 2.2-2.2 0-.94-.36-1.54-1.1-1.81-1.57-.58-1.2-.45-2.73-.98a8.71 8.71 0 01-4.1-3.02c-.6-.8-.9-1.81-.9-3.02 0-1.81.66-3.37 1.96-4.67S177.58 14 179.4 14c1.82 0 3.38.65 4.68 1.95s1.95 2.86 1.95 4.67h-4.4c0-1.19-1-2.2-2.2-2.2-1.2 0-2.2 1.01-2.2 2.22 0 .78.3 1.38.94 1.78 1.3.84 1.15.68 2.71 1.14a8.42 8.42 0 014.24 2.87c.6.79.92 1.8.92 3.04 0 1.83-.65 3.38-1.95 4.68s-2.86 1.95-4.67 1.95c-1.83 0-3.39-.65-4.69-1.95s-1.95-2.85-1.95-4.68h4.42c0 1.22.98 2.2 2.2 2.2zm14.51-17.53h13.1v4.36h-8.73v4.38h6.55v4.36h-6.55v4.36h8.73v4.37h-13.1V14.15zm20.72 19.54a2.79 2.79 0 115.58 0 2.79 2.79 0 01-5.58 0zm18.67-19.54h9.83v4.36h-5.46v17.47h-4.37V18.51h-5.46v-4.36h5.46zm36.69 0l-9.83 22.24-9.81-22.24h4.61l5.18 11.9 5.18-11.9H270z"
    />
  </svg>
);

export default Logo;
