import type { DiffuseTvStateOptions } from "@diffuse.tv/player-core";

const $ = process.env;
export const NODE_ENV =
  $.REACT_APP_ENVIRONMENT ||
  $.REACT_APP_NODE_ENV ||
  $.NODE_ENV ||
  "development";

export const stateOptions: Partial<DiffuseTvStateOptions> = {
  environment: NODE_ENV,
  graphqlURL:
    (NODE_ENV === "development" && "http://localhost:4000/graphql") || "",
  // storageURL: (NODE_ENV === "development" && "http://localhost:4000/storage") || "",
  hydrate: true,
  hydratePrefix: (NODE_ENV === "development" && "development-") || "",
};
