import "./setup";
import "./index.css";
import * as Sentry from "@sentry/browser";
import React from "react";
import { render } from "react-dom";
import { NODE_ENV } from "./configs";
import DiffuseTvComponent from "./component";
import { createState } from "./state";

if (NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://398344a0941248ae800efb0795712998@sentry.io/1777154",
  });
}

(async function () {
  const state = await createState();

  render(
    <div className="App">
      <DiffuseTvComponent
        state={state}
        debug={window.location.search === "?debug"}
      />
    </div>,
    document.getElementById("root")
  );
})();
