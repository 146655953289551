import React, { Component } from "react";

import type { DiffuseTvState } from "@diffuse.tv/player-core";
import { ILocalBroadcastReport } from "@diffuse.tv/player-core/build/database/collections/broadcast-report";

export interface ImageComponentProps {
  state: DiffuseTvState;
}

export default class ImageComponent extends Component<ImageComponentProps> {
  private playing_unique_id?: string = undefined;
  private playing_timeout?: NodeJS.Timeout = undefined;

  componentDidMount = () => this.playNextAfterDuration();
  componentDidUpdate = () => this.playNextAfterDuration();

  playNextAfterDuration() {
    const old_playing_unique_id = this.playing_unique_id;
    const playing_unique_id = this.props.state.schedule.playing.unique_id;

    // TODO: we should change this later to a global key instead of evaluating manually
    if (old_playing_unique_id !== playing_unique_id) {
      if (this.playing_timeout) {
        clearTimeout(this.playing_timeout);
        this.playing_timeout = undefined;
      }

      this.playing_unique_id = playing_unique_id;
      this.playing_timeout = setTimeout(
        () => this.onEnd(playing_unique_id),
        this.props.state.schedule.playing.duration
      );
    }
  }
  onEnd(playing_unique_id?: string) {
    const state = this.props.state;
    const broadcastPlan = state.schedule.playingBroadcastPlan;
    const startedAt =
      (+state.schedule.playingSetAt > 0 && +state.schedule.playingSetAt) ||
      Date.now();
    const duration = Date.now() - startedAt;

    // create report
    if (broadcastPlan) {
      state.schedule.createReport(
        "FULLY_BROADCASTED",
        broadcastPlan,
        new Date(),
        duration
      );
    }

    state.schedule.playNext(playing_unique_id);
  }

  onError(err: any) {
    const state = this.props.state;
    const broadcastPlan = state.schedule.playingBroadcastPlan;
    const startedAt =
      (+state.schedule.playingSetAt > 0 && +state.schedule.playingSetAt) ||
      Date.now();
    const uploadIndex = state.schedule.playingUploadIndex;
    const duration = Date.now() - startedAt;

    // create report
    if (broadcastPlan) {
      // check if we broadcasted or not
      const broadcastStatus: ILocalBroadcastReport["broadcast_status"] =
        uploadIndex > 0 || duration > 1000
          ? "PARTIALLY_BROADCASTED"
          : "NOT_BROADCASTED";

      state.schedule.createReport(
        broadcastStatus,
        broadcastPlan,
        new Date(),
        duration,
        String(err)
      );
    }

    state.schedule.playNext();
  }

  render() {
    const state = this.props.state;
    const { playing } = state.schedule;

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundImage: `url(${playing.url})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    );
  }
}
