import React, { Component } from "react";
import { observer } from "mobx-react";

import type { DiffuseTvState } from "@diffuse.tv/player-core";

export interface DebugComponentProps {
  state: DiffuseTvState;
  screen: string;
  ScreenComponent: any;
  screenProps: { [key: string]: any };
}

@observer
export default class DebugComponent extends Component<DebugComponentProps> {
  render() {
    const {
      // ScreenComponent,
      state: { player, schedule },
      state,
      // screen,
      // screenProps
    } = this.props;

    return (
      <div
        style={{
          position: "absolute",
          display: "block",
          top: 20,
          bottom: "auto",
          left: 20,
          right: "auto",
          width: "450px",
          height: "auto",
          padding: "0 20px",
          background: `rgba(40,40,40,0.5)`,
          color: "white",
          textAlign: "left",
          fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
          fontSize: "0.6em",
          overflowWrap: "break-word",
          zIndex: 99999,
        }}
      >
        <h4>Stats for nerds:</h4>

        <p>
          <b>State:</b>
          <br />
          <span>
            Is Ready: {String(state.ready)}
            <br />
          </span>
          <span>
            Options: <br />
          </span>
          {Object.entries(state.options).map(([name, value], i) => (
            <span key={i}>
              - {name}: {String(value)}
              <br />
            </span>
          ))}
        </p>

        <p>
          <b>Player:</b>
          <br />
          <span>
            Unique ID: {player.uniqueId}
            <br />
          </span>
          <span>
            Identifier: {player.identifier || "..."}
            <br />
          </span>
          <span>
            Last Data Sync: {String(player.lastSync)}
            <br />
          </span>
          <span>
            Is Loaded: {String(player.isLoaded)}
            <br />
          </span>
          <span>
            Status: {String(player.status)}
            <br />
          </span>
          <br />
          <span>
            Error: {String(player.error)}
            <br />
          </span>
        </p>
        <p>
          <b>Schedule:</b>
          <br />
          <span>
            Playing BroadcastPlan:{" "}
            {(schedule.playing.broadcastPlan &&
              String(schedule.playing.broadcastPlan._id)) ||
              "null"}
            <br />
          </span>
          <span>
            Playing BroadcastPlan Part:{" "}
            {(schedule.playing.uploads.length > 0 &&
              String(schedule.playingUploadIndex + 1)) ||
              0}
            /{String(schedule.playing.uploads.length)}
            <br />
          </span>
          <br />
          <span>
            Error: {String(schedule.error)}
            <br />
          </span>
        </p>
      </div>
    );
  }
}
