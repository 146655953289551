import React, { Component } from "react";
import { observer } from "mobx-react";

import type { DiffuseTvState } from "@diffuse.tv/player-core";

import PlaybackQRCodeLayout from "./layouts/playback-qr-code";
import ScreenLayout from "./layouts/screen";

import {
  DebugComponent,
  AudioComponent,
  ImageComponent,
  VideoComponent,
  InitializingComponent,
  LoadingComponent,
  EmptyComponent,
  SetupComponent,
  ErrorComponent,
  BindComponent,
} from "./screens";

export enum ScreenTypes {
  INITIALIZING = "INITIALIZING",
  DEVICE_BIND = "DEVICE_BIND",
  LOADING = "LOADING",
  SETUP = "SETUP",
  EMPTY = "EMPTY",
  PLAYING_VIDEO = "PLAYING_VIDEO",
  PLAYING_AUDIO = "PLAYING_AUDIO",
  PLAYING_IMAGE = "PLAYING_IMAGE",
  ERROR = "ERROR",
}

export interface Props {
  state: DiffuseTvState;
  debug: boolean;

  AudioComponent: any;
  ImageComponent: any;
  VideoComponent: any;
  InitializingComponent: any;
  LoadingComponent: any;
  EmptyComponent: any;
  SetupComponent: any;
  ErrorComponent: any;
  BindComponent: any;
}

@observer
export default class DiffuseTvComponent extends Component<Props, any> {
  public _state?: DiffuseTvState;

  static defaultProps = {
    debug: false,
    AudioComponent,
    ImageComponent,
    VideoComponent,
    InitializingComponent,
    LoadingComponent,
    EmptyComponent,
    SetupComponent,
    ErrorComponent,
    BindComponent,
  };

  getScreen(state = this.props.state) {
    return (
      ((state.player.error || state.schedule.error) && ScreenTypes.ERROR) ||
      ((!state.player.isLoaded || !state.ready) && ScreenTypes.INITIALIZING) ||
      (state.player.status === "REGISTERED" && ScreenTypes.DEVICE_BIND) ||
      (state.player.status === "BINDED" && ScreenTypes.SETUP) ||
      (state.player.status === "ACTIVATED" &&
        ((state.schedule.countBroadcastPlans === 0 && ScreenTypes.EMPTY) ||
          (!state.schedule.playing.upload && ScreenTypes.LOADING) ||
          (state.schedule.playing.upload.type === "video" &&
            ScreenTypes.PLAYING_VIDEO) ||
          (state.schedule.playing.upload.type === "image" &&
            ScreenTypes.PLAYING_IMAGE))) ||
      // state.schedule.playing.upload.type === 'audio' && ScreenTypes.PLAYING_AUDIO ||
      ScreenTypes.ERROR
    );
  }

  getScreenProps(
    screen: ScreenTypes,
    state: DiffuseTvState = this.props.state
  ) {
    const props = {
      state,
    };

    switch (screen) {
      // case ScreenTypes.PLAYING_AUDIO:
      // case ScreenTypes.PLAYING_IMAGE:
      // case ScreenTypes.PLAYING_VIDEO:
      //   return {
      //     ...props,
      //   }

      default:
        return {
          ...props,
        };
    }
  }

  getScreenComponent(
    state?: DiffuseTvState,
    screen?: ScreenTypes,
    screenProps?: any
  ) {
    if (!state) {
      state = this.props.state;
    }

    if (!screen) {
      screen = this.getScreen(state);
    }

    if (!screenProps) {
      screenProps = this.getScreenProps(screen, state);
    }

    const {
      AudioComponent,
      ImageComponent,
      VideoComponent,
      InitializingComponent,
      LoadingComponent,
      SetupComponent,
      EmptyComponent,
      ErrorComponent,
      BindComponent,
    } = this.props;

    const ScreenComponent =
      (screen === ScreenTypes.INITIALIZING && InitializingComponent) ||
      (screen === ScreenTypes.LOADING && LoadingComponent) ||
      (screen === ScreenTypes.DEVICE_BIND && BindComponent) ||
      (screen === ScreenTypes.PLAYING_AUDIO && AudioComponent) ||
      (screen === ScreenTypes.PLAYING_IMAGE && ImageComponent) ||
      (screen === ScreenTypes.PLAYING_VIDEO && VideoComponent) ||
      (screen === ScreenTypes.ERROR && ErrorComponent) ||
      (screen === ScreenTypes.SETUP && SetupComponent) ||
      (screen === ScreenTypes.EMPTY && EmptyComponent) ||
      null;

    return ScreenComponent;
  }

  getWrapperComponent(
    state?: DiffuseTvState,
    screen?: ScreenTypes,
    screenProps?: any
  ) {
    if (!state) {
      state = this.props.state;
    }

    if (!screen) {
      screen = this.getScreen(state);
    }

    if (!screenProps) {
      screenProps = this.getScreenProps(screen, state);
    }

    if (
      [
        ScreenTypes.PLAYING_VIDEO,
        ScreenTypes.PLAYING_AUDIO,
        ScreenTypes.PLAYING_IMAGE,
      ].includes(screen)
    ) {
      return PlaybackQRCodeLayout;
    }

    return ScreenLayout;
  }

  public get debugging() {
    const state = this.props.state;
    const settings = (state.player.data && state.player.data.settings) || {};

    return this.props.debug || settings.debug || false;
  }

  render() {
    // evaluate which state we are right now
    const state = this.props.state;
    const screen = this.getScreen(state);
    const screenProps = this.getScreenProps(screen, state);
    const ScreenComponent = this.getScreenComponent(state, screen, screenProps);
    const Wrapper = this.getWrapperComponent(state, screen, screenProps);

    return (
      <Wrapper {...{ state, screen }}>
        {this.debugging && (
          <DebugComponent
            {...{ ScreenComponent, state, screen, screenProps }}
          />
        )}
        <ScreenComponent {...screenProps} />
      </Wrapper>
    );
  }
}
