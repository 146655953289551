import React, { Component } from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import QRCode from "react-qr-code";

import type { DiffuseTvState } from "@diffuse.tv/player-core";
import { BIND_SCREEN_RENDER_INTERVAL } from "@diffuse.tv/player-core/build/constants";

import SplitScreen from "../components/split-screen";

@observer
export default class BindComponent extends Component<{
  state: DiffuseTvState;
}> {
  private timer?: NodeJS.Timeout = undefined;

  componentDidMount() {
    this.timer = setInterval(
      () => this.forceUpdate(),
      BIND_SCREEN_RENDER_INTERVAL
    );
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  render() {
    const state = this.props.state;
    const token = state.player.getBindToken();

    return (
      <SplitScreen
        state={this.props.state}
        title="Write the following QR-Code token in your dashboard"
        description="Log in into your dashboard account > Players > Add Player > Bind Player."
      >
        <QRCodeContainer className="qr-code-container">
          <QRCode
            level="L"
            size={200}
            value={`${state.options.dashboardURL}/players/bind?token=${token}`}
          />
          <b style={{ fontSize: "1.5rem", marginTop: "20px" }}>{token}</b>
        </QRCodeContainer>
      </SplitScreen>
    );
  }
}

const QRCodeContainer = styled.div`
  padding: 1.5625rem;
  color: black;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
