import { DiffuseTvState } from "@diffuse.tv/player-core";
import { stateOptions, NODE_ENV } from "./configs";
import getUniqueID from "./identifier";
import * as ipfs from "./ipfs/client";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import IPFSWorker from "service-worker-loader!./ipfs/service-worker";

export async function createState() {
  const hardwareUniqueIdentifier = await getUniqueID();
  await IPFSWorker();

  const state = new DiffuseTvState({
    ...stateOptions,
    id: window.location.hash?.replace(/^#/, "") || "",

    hardwareUniqueIdentifier,

    storage: localStorage,
    ipfs,
  });

  if (NODE_ENV === "development") {
    // @ts-ignore
    window.state = state;
  }

  return state;
}
