import React, { Component } from "react";
import styled from "@emotion/styled";

import type { DiffuseTvState } from "@diffuse.tv/player-core";
import { ILocalBroadcastReport } from "@diffuse.tv/player-core/build/database/collections/broadcast-report";

export interface VideoComponentProps {
  state: DiffuseTvState;
}

export default class VideoComponent extends Component<VideoComponentProps> {
  public video: any;

  // shouldComponentUpdate(nextProps) {
  //   const { props } = this

  //   return (
  //     nextProps.state.schedule.playingURL !== props.state.schedule.playingURL
  //   )
  // }

  componentDidMount() {
    this.play();
  }
  componentDidUpdate() {
    this.play();
  }

  play() {
    if (!this.video) return this.forceUpdate();

    var isPlaying =
      this.video.currentTime > 0 &&
      !this.video.paused &&
      !this.video.ended &&
      this.video.readyState > 2;

    if (!isPlaying) {
      this.video.play();
    }
  }

  onEnd(playing_unique_id?: string) {
    const state = this.props.state;
    const broadcastPlan = state.schedule.playingBroadcastPlan;
    const startedAt =
      (+state.schedule.playingSetAt > 0 && +state.schedule.playingSetAt) ||
      Date.now();
    const duration = Date.now() - startedAt;

    // create report
    if (broadcastPlan) {
      state.schedule.createReport(
        "FULLY_BROADCASTED",
        broadcastPlan,
        new Date(),
        duration
      );
    }

    state.schedule.playNext(playing_unique_id);
  }

  onError(err: any) {
    const state = this.props.state;
    const broadcastPlan = state.schedule.playingBroadcastPlan;
    const startedAt =
      (+state.schedule.playingSetAt > 0 && +state.schedule.playingSetAt) ||
      Date.now();
    const uploadIndex = state.schedule.playingUploadIndex;
    const duration = Date.now() - startedAt;

    // create report
    if (broadcastPlan) {
      // check if we broadcasted or not
      const broadcastStatus: ILocalBroadcastReport["broadcast_status"] =
        uploadIndex > 0 || duration > 1000
          ? "PARTIALLY_BROADCASTED"
          : "NOT_BROADCASTED";

      state.schedule.createReport(
        broadcastStatus,
        broadcastPlan,
        new Date(),
        duration,
        String(err)
      );
    }

    state.schedule.playNext();
  }

  render() {
    const state = this.props.state;
    const { playing, next } = state.schedule;
    const unique_id = playing.unique_id;

    return (
      <>
        {!!playing.upload && !!playing.url && (
          <DisplayVideo
            muted
            src={playing.url}
            key={playing.unique_id + String(playing.uploadIndex)}
            ref={(video) => (this.video = video)}
            onEnded={() => this.onEnd(unique_id)}
            onError={(err: any) => this.onError(err)}
          />
        )}
        {!!next.upload && !!next.url && (
          <HideVideo src={next.url} key={next.unique_id} />
        )}
      </>
    );
  }
}

const DisplayVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const HideVideo = styled.video`
  display: none;
`;
