import React, { PureComponent } from "react";
import SplitScreen from "../components/split-screen";
import type { DiffuseTvState } from "@diffuse.tv/player-core";

export default class EmptyScreen extends PureComponent<{
  state: DiffuseTvState;
}> {
  render() {
    return (
      <SplitScreen
        state={this.props.state}
        title="Ready to play!"
        description="Your display is connected. To control the media, please use Diffuse.tv's dashboard at https://dashboard.diffuse.tv."
      />
    );
  }
}
