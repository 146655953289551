export const getViewportSize = () => {
  const width = window?.innerWidth || 0;
  const height = window?.innerHeight || 0;

  return { width, height };
};

export const getViewportOrientation = (): "landscape" | "portrait" => {
  const { width, height } = getViewportSize();
  return width > height ? "landscape" : "portrait";
};

export const getViewportRatio = (): number => {
  const { width, height } = getViewportSize();
  return width > height ? width / height : height / width;
};

export const getViewportMin = (): number => {
  const { width, height } = getViewportSize();
  return Math.min(width, height);
};

export const getViewportMax = (): number => {
  const { width, height } = getViewportSize();
  return Math.max(width, height);
};
