import React, { PureComponent } from "react";
import styled from "@emotion/styled";

export default class ScreenLayoutComponent extends PureComponent {
  render() {
    const { children } = this.props;
    return (
      <ScreenLayoutWrapper className="screen-layout-wrapper">
        {children}
      </ScreenLayoutWrapper>
    );
  }
}

const ScreenLayoutWrapper = styled.main`
  width: 100%;
  height: 100%;
  position: relative;
  color: white;
  background: black;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;
