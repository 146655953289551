import React, { PureComponent } from "react";
import SplitScreen from "../components/split-screen";
import type { DiffuseTvState } from "@diffuse.tv/player-core";

export default class LoadingComponent extends PureComponent<{
  state: DiffuseTvState;
}> {
  render() {
    return (
      <SplitScreen
        state={this.props.state}
        title="We're loading content"
        description="Please wait while we preload the content ahead of time to ensure the best viewing experience."
      />
    );
  }
}
