import React, { PureComponent } from "react";
import SplitScreen from "../components/split-screen";
import type { DiffuseTvState } from "@diffuse.tv/player-core";

export default class ErrorComponent extends PureComponent<{
  state: DiffuseTvState;
}> {
  render() {
    return (
      <SplitScreen
        state={this.props.state}
        title="Something went wrong, but we are working on it!"
        description="Our team is working quickly to fix this problem. Soon you'll be enjoying the best media content."
      />
    );
  }
}
