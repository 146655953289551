import React, { PureComponent } from "react";
import styled from "@emotion/styled";
import QRCode from "react-qr-code";
import { getViewportMin } from "../ratios";

import { DiffuseTvState } from "@diffuse.tv/player-core";

import ScreenLayout from "./screen";

export interface PlaybackQRCodeLayoutProps {
  state: DiffuseTvState;
}

export default class PlaybackQRCodeLayout extends PureComponent<PlaybackQRCodeLayoutProps> {
  render() {
    const {
      state: {
        schedule: { playing },
      },
      children,
    } = this.props;

    const qr_code_size = Math.floor(getViewportMin() * (3 / 16));
    const qr_code_margin = Math.floor(getViewportMin() * (2 / 32));
    const qr_code_padding = Math.floor(getViewportMin() * (1 / 64));

    return (
      <>
        <ScreenLayout>{children}</ScreenLayout>
        {!!playing.engagement && (
          <QRCodeWrapper
            size={qr_code_size}
            margin={qr_code_margin}
            padding={qr_code_padding}
            className="qr-code-wrapper"
          >
            <QRCode
              level="L"
              value={playing.engagement}
              size={qr_code_size - qr_code_padding * 2}
            />
          </QRCodeWrapper>
        )}
      </>
    );
  }
}

const QRCodeWrapper = styled.div<{
  size: number;
  margin: number;
  padding: number;
}>`
  z-index: 20;
  position: absolute;
  background-color: white;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  right: ${({ margin }) => margin}px;
  bottom: ${({ margin }) => margin}px;
  padding: ${({ padding }) => padding}px;
`;
